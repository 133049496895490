<template>
    <div class="loading-dots">
        <span>.</span>
    </div>
</template>

<script>
export default {
    name: 'loadingDots',
}
</script>

<style scoped lang="scss">
.animation {
    animation-name: blink;
    animation-duration: 1000ms;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
}

.loading-dots {
    display: inline-block;
    font-size: 180%;
    line-height: .8;

    &--large {
        font-size: 360%;
    }

    &--xlarge {
        font-size: 540%;
    }

    span {
        @extend .animation;
        animation-delay: 250ms;
        display: inline-block;
        position: relative;
    }

    &:before,
    &:after {
        @extend .animation;
        content: '.';
        display: inline-block;
        position: relative;
    }

    &:before {
        animation-delay: 0ms;
    }

    &:after {
        animation-delay: 500ms;
    }
}

@keyframes blink {
    0% {
        opacity: 0.1;
        transform: translate3d(0, 2px, 0);
    }

    20% {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }

    100% {
        opacity: 0.1;
        transform: translate3d(0, 3px, 0);
    }
}
</style>
