import { render, staticRenderFns } from "./LoadingDots.vue?vue&type=template&id=0b4a9718&scoped=true&"
import script from "./LoadingDots.vue?vue&type=script&lang=js&"
export * from "./LoadingDots.vue?vue&type=script&lang=js&"
import style0 from "./LoadingDots.vue?vue&type=style&index=0&id=0b4a9718&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b4a9718",
  null
  
)

component.options.__file = "LoadingDots.vue"
export default component.exports