<template>
	<div class="wizard-loading">
		<transition name="loading" appear v-on:after-leave="leave">
			<div class="wizard-loading__content" v-if="loading">
				<img :src="require('@/assets/images/instantkegs-symbol.png')" alt="Loading Logo">
				<loading-dots></loading-dots>
			</div>
		</transition>
	</div>
</template>

<script>
// Dependencies
import qs from 'qs'
import LoadingDots from '@/components/LoadingDots.vue'

// Component
export default {
	name: 'wizardLoading',
	components: {
		LoadingDots,
	},
	computed: {
		loading() {
			return this.$store.getters['cart/loading']
		},
	},
	mounted(){

		try {
			this.$store.dispatch('cart/load')
		} catch (e) {}
	},
	methods: {
		leave() {
			this.$router.push({ name: 'step1', query: this.$route.query })
		},
	},
}
</script>

<style scoped lang="scss">
.wizard-loading {
	display: flex;
	flex-direction: row;
	justify-content: center;

	&__content {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
}

.loading {
	// animation-duration: 550ms;

	&-enter-active {
		animation: slideIn 500ms $easing-material;
		// animation-name: slideIn;
	}

	&-leave-active {
		animation: slideOut 500ms $easing-material;
		// animation-name: slideOut;
	}
}

@keyframes slideIn {
	0% {
		opacity: 0;
		transform: translate3d(-100%, 0, 0);
	}

	100% {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}
}

@keyframes slideOut {
	0% {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}

	100% {
		opacity: 0;
		transform: translate3d(100%, 0, 0);
	}
}
</style>
